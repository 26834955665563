import header from './header';
import footer from './footer';
import dangerousHtml from './dangerous-html';
import customSelect from './custom-select';
import replaceFontAwesome from './replace-font-awesome';

export default `
  ::-webkit-inner-spin-button {
    display: none;
  }

  ::-webkit-calendar-picker-indicator {
    display: none;
  }

  @media only screen and (min-width: 769px) {
    ::-webkit-scrollbar {
      appearance: none;
    }
  
    ::-webkit-scrollbar:vertical {
      width: 12px;
    }
  
    ::-webkit-scrollbar:horizontal {
      height: 12px;
    }
  
    ::-webkit-scrollbar-track {
      background-color: #EFEFEF;
      border: 1px solid #333333;
    }
  
    ::-webkit-scrollbar-thumb {
      background-color: #007575;
      border: 1px solid #333333;
      border-radius: 50px;
    }
  
    * {
      scrollbar-color: #007575 #EFEFEF;
      scrollbar-width: thin;
    }
  }

  dialog {
    color: inherit;
  }

  body {
    overflow-x: hidden;
  }

  body.drawer-open {
    overflow-y: hidden;
  }

  @media all {
    select::-ms-expand {
      display: none;
    }
  }

  ${header}
  ${footer}
  ${dangerousHtml}
  ${customSelect}
  ${replaceFontAwesome}
`;
