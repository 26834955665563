import encodeSvgUrl from '../libs/encodeSvgUrl';

import theme from './theme';

const svgTick = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="${theme.COLOR_WHITE}" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>`;
const svgFacebook = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="${theme.COLOR_BRAND_PRIMARY_DARK}" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path></svg>`;
const svgTwitter = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="${theme.COLOR_BRAND_PRIMARY_DARK}" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path></svg>`;
const svgYouTube = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="${theme.COLOR_BRAND_PRIMARY_DARK}" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path></svg>`;
const svgInstagram = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="${theme.COLOR_BRAND_PRIMARY_DARK}" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path></svg>`;
const svgChevronRightGreen = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="${theme.COLOR_BRAND_PRIMARY}" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path></svg>`;
const svgChevronRightWhite = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="${theme.COLOR_WHITE}" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path></svg>`;
const svgChevronLeftWhite = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="${theme.COLOR_WHITE}" d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"></path></svg>`;
const svgChevronDown = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="${theme.COLOR_WHITE}" d="M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z"></path></svg>`;
const svgMinus = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="${theme.COLOR_WHITE}" d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg>`;
const svgPlus = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="${theme.COLOR_WHITE}" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg>`;
const svgPlay = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="${theme.COLOR_BRAND_PRIMARY_DARK}" d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"></path></svg>`;

export default `
  .icon.fas,
  .icon.fab {
    height: 1em;
    line-height: 1em;
  }
  .icon.fab:before {
    content: '';
    display: inline-block;
    height: 1em;
  }
  .icon.fab.fa-facebook-f:before {
    width: .625em;
    background-image: ${encodeSvgUrl(svgFacebook)};
  }
  .icon.fab.fa-twitter:before {
    width: 1em;
    background-image: ${encodeSvgUrl(svgTwitter)};
  }
  .icon.fab.fa-youtube:before {
    width: 1.125em;
    background-image: ${encodeSvgUrl(svgYouTube)};
  }
  .icon.fas.fa-play:before {
    width: 0.875em;
    margin-left: 0.2em;
    background-image: ${encodeSvgUrl(svgPlay)};
  }
  .icon.fab.fa-instagram:before {
    width: .875em;
    background-image: ${encodeSvgUrl(svgInstagram)};
  }
  .fas:before {
    content: '';
    display: inline-block;
    height: 1em;
  }
  .icon.fas.fa-check:before {
    width: 1em;
    background-image: ${encodeSvgUrl(svgTick)};
  }
  .icon.fas.fa-chevron-right:before {
    width: .625em;
    background-image: ${encodeSvgUrl(svgChevronRightGreen)};
  }
  .pnav__title .icon.fas.fa-chevron-right:before {
    background-image: ${encodeSvgUrl(svgChevronRightWhite)};
  }
  .icon.fas.fa-chevron-left:before {
    width: .625em;
    background-image: ${encodeSvgUrl(svgChevronLeftWhite)};
  }
  .fas.fa-minus:before {
    width: .875em;
    background-image: ${encodeSvgUrl(svgMinus)};
  }
  .fas.fa-plus:before {
    width: .875em;
    background-image: ${encodeSvgUrl(svgPlus)};
  }

  @media only screen and (max-width: 767px) {
    .footer__column-heading:after {
      background-image: ${encodeSvgUrl(svgChevronDown)};
    }
  }
`;
