export default `
  @media only screen and (min-width: 768px) {
    .footer__column-content {
      display: block !important; // needed to override AccordionContent js
    }
    .footer__column-button {
      pointer-events: none;
    }
  }
`;
