export default `
  .pnav--setDisplay {
    display: block;
    transform: translate3d(0, 0, 0);
  }

  @media only screen and (max-width: 768px) {
    .pnav--setDisplay {
      max-height: 100%;
      overflow-y: auto;
    }
  }
`;
