export const stripUnit = (value) => {
  const match = /[0-9]+\.?[0-9]*/.exec(value);
  return match ? Number(match[0]) : match;
};

export const getUnit = (value) => {
  const match = /[a-z]+|%/i.exec(value);
  return match ? match[0] : '';
};

export const multiply = (value, multiple) => {
  const number = stripUnit(value) || 0;
  const unit = getUnit(value) || '';
  return `${number * multiple}${unit}`;
};

export const divide = (value, denominator) => {
  const number = stripUnit(value) || 0;
  const unit = getUnit(value) || '';
  return `${number / denominator}${unit}`;
};

export const subtract = (value, amount) => {
  const number = stripUnit(value) || 0;
  const unit = getUnit(value) || '';
  return `${number - amount}${unit}`;
};
