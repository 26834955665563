export default function encodeSvgUrl(svg) {
  let encoded = svg;
  encoded = encoded.replace(/"/g, '\'');
  encoded = encoded.replace(/</g, '%3C');
  encoded = encoded.replace(/>/g, '%3E');
  encoded = encoded.replace(/&/g, '%26');
  encoded = encoded.replace(/#/g, '%23');

  return `url("data:image/svg+xml;charset=utf8,${encoded}")`;
}
