/**
 * Styles for use in targeting selectors in Dangerously set html
 */

import theme from './theme';

export default `
  .highlight {
    color: ${theme.COLOR_BRAND_PRIMARY}
  }
  .warn {
    color: ${theme.COLOR_AMBER_ORANGE}
  }
  .error {
    color: ${theme.COLOR_DANGER}
  }
`;
