/* eslint-disable no-extend-native */
if (!Array.prototype.findLastIndex) {
  Object.defineProperty(Array.prototype, 'findLastIndex', {
    value: function findLastIndex(predicate) {
      if (this == null) {
        throw TypeError('"this" is null or not defined');
      }
      if (typeof predicate !== 'function') {
        throw TypeError('predicate must be a function');
      } console.warn(this);
      let l = this.length;
      // eslint-disable-next-line no-plusplus
      while (l--) {
        if (predicate(this[l], l, this)) return l;
      }
      return -1;
    },
    configurable: true,
    writable: true,
  });
}
